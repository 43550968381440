// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  domain: "",
  apiUrl: "https://punchrides.net:3001",
  socketUrl: "https://punchrides.net:8082",
  googleapiKey: "",
  googleloginClientid: "",
  facebookloginClientid: "",
  firebase: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    //measurementId: "ss"
  }
};


